import Icon from '@/components/base/icon';
import CustomButton from '../custom-button';
import LikeButton from '../like-button';
import { ICardLikeOrLightboxProps } from './types';

const CardLikeOrLightbox: React.FC<ICardLikeOrLightboxProps> = ({
  like,
  lightbox,
  id,
}) => {
  return (
    <div className="card-thumbnail-toolbar">
      {like && <LikeButton id={id} />}
      {lightbox && (
        <CustomButton
          blur={true}
          background="#000"
          customColor="#FFF"
          round={50}
          padding={false}
          className="card-thumbnail-toolbar-button"
          iconBefore={
            <Icon size="medium" fill="white" name="icon-show-image" />
          }
        />
      )}
    </div>
  );
};

export default CardLikeOrLightbox;
