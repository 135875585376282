import Icon from '@/components/base/icon';
import {
  deleteUserFavorites,
  getUserFavorites,
  postUserFavorites,
} from '@/services/goturkiye';
import { Cookie } from '@/utils/cookie';
import { functions } from '@wap-client/core';
import { useState, useEffect } from 'react';
import { LikeButtonProps } from './type';

const LikeButton: React.FC<LikeButtonProps> = ({ id }) => {
  const [isLiked, setIsLiked] = useState(false);
  const token = Cookie.get('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getUserFavorites();
        const recordIds = data?.data?.recordIds || [];

        if (recordIds.length > 0) {
          const liked = recordIds.some((item) => item === id);
          setIsLiked(liked);
        } else {
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    };

    fetchData();
  }, []);

  const handleLike = async (
    evnt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evnt.preventDefault();
    evnt.stopPropagation();

    if (!token) {
      console.log('Beğeni için giriş yapmalısınız popup ı yapılabilir.');
      return;
    }

    if (id) {
      if (isLiked) {
        try {
          await deleteUserFavorites(id);
          setIsLiked(false);
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          postUserFavorites({ recordId: id });
          setIsLiked(true);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  return (
    <button
      type="button"
      onClick={handleLike}
      className={functions.classnames('like-button', isLiked && 'liked')}
    >
      <Icon size="small" name={isLiked ? 'icon-heart-filled' : 'icon-heart'} />
    </button>
  );
};

export default LikeButton;
