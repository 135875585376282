import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import Image from '@/components/base/image';
import CustomButton from '@/components/widgets/custom-button';
import { path, useApp } from '@wap-client/core';
import { functions } from '@wap-client/core';
import { UICard } from './types';

import useMomentLocale from '@/hooks/useMomentLocale';
import CardDayDetail from './CardDayDetail';
import CardDistrictAndCityDetail from './CardDistrictAndCityDetail';
import CardDetail from './CardDetail';
import CardLikeOrLightbox from './CardLikeOrLightbox';
import CardWithAnchor from './CardWithAnchor';

const animations = {
  card: {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Card: React.FC<UICard> = ({
  image,
  title,
  description,
  like,
  lightbox,
  lightboxGalleryId,
  link,
  day,
  className,
  style,
  index,
  showTopList,
  isDiscover = false,
  city,
  district,
  isRiviera,
  motion: motionProps,
  place,
  endDate,
  startDate,
  id = '',
  isContentCity,
}) => {
  const app = useApp();
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  useMomentLocale(app.language || 'en');

  return (
    <motion.figure
      ref={ref}
      variants={animations.card}
      animate={`${isInView ? 'show' : 'hidden'}`}
      initial="hidden"
      viewport={{ once: true, amount: 0.25 }}
      className={functions.classnames(className, 'card', {
        'card-lightbox': lightbox,
      })}
      style={style || undefined}
      {...motionProps}
    >
      <CardWithAnchor
        dataSrc={path.asset(app.environment, image?.src)}
        dataFancybox={
          !!lightboxGalleryId ? `gallery-${lightboxGalleryId}` : 'single'
        }
        link={link}
        lightbox={lightbox}
      >
        <div className={functions.classnames('card-thumbnail')}>
          {(like || lightbox) && (
            <CardLikeOrLightbox id={id} lightbox={lightbox} like={like} />
          )}

          {district && city && isContentCity && (
            <CardDistrictAndCityDetail city={city} district={district} />
          )}

          {day && <CardDayDetail day={day} />}

          {title && !description && isDiscover && (
            <CustomButton
              className="card-thumbnail-title-button"
              background="#FFF"
              customColor="#000"
              blur={false}
              round={20}
              opacity={0.5}
            >
              {title}
            </CustomButton>
          )}

          <Image className="card-thumbnail-image" {...image} alt={title} />
        </div>

        {!isDiscover && (
          <CardDetail
            city={city}
            description={description}
            endDate={endDate}
            index={index}
            isContentCity={isContentCity}
            place={place}
            showTopList={showTopList}
            startDate={startDate}
            title={title}
          />
        )}
      </CardWithAnchor>
    </motion.figure>
  );
};

export default Card;
