import Anchor from '@/components/base/anchor';
import { ICardWithAnchorProps } from './types';

const CardWithAnchor: React.FC<ICardWithAnchorProps> = ({
  link,
  children,
  lightbox,
  dataSrc,
  dataFancybox,
}) => {
  if (link) {
    return <Anchor {...link}>{children}</Anchor>;
  }

  if (lightbox) {
    return (
      <div
        data-src={dataSrc}
        data-fancybox={dataFancybox}
        style={{ cursor: 'pointer' }}
        // data-caption={description}
      >
        {children}
      </div>
    );
  }

  return children;
};

export default CardWithAnchor;
