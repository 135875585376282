import Icon from '@/components/base/icon';
import CustomButton from '../custom-button';
import { ICardDistrictAndCityDetailProps } from './types';

const CardDistrictAndCityDetail: React.FC<ICardDistrictAndCityDetailProps> = ({
  city,
  district,
}) => {
  return (
    <CustomButton
      blur={true}
      background="#000"
      customColor="#FFF"
      round={50}
      iconAfter={<Icon size={'small'} name="icon-direct-arrow" />}
      className="card-thumbnail-badge"
    >
      {city} / {district}
    </CustomButton>
  );
};

export default CardDistrictAndCityDetail;
