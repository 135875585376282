import CardEventDetail from './CardEventDetail';
import { ICardDetailProps } from './types';

const CardDetail: React.FC<ICardDetailProps> = ({
  showTopList,
  index,
  title,
  city,
  isContentCity,
  place,
  startDate,
  endDate,
  description,
}) => {
  return (
    <div
      className={`card-detail ${showTopList ? 'card-detail-container' : ''}`}
    >
      {showTopList && (
        <div className="card-detail-container-top-number">{index! + 1}</div>
      )}

      <>
        {title && <div className="card-detail-title">{title}</div>}
        {city && !isContentCity && <p className="card-detail-city">{city}</p>}
        {(place || startDate || endDate) && (
          <CardEventDetail
            endDate={endDate}
            place={place}
            startDate={startDate}
          />
        )}
        {description && (
          <div
            className="card-detail-desc"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </>
    </div>
  );
};

export default CardDetail;
