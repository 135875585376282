import Icon from '@/components/base/icon';
import moment from 'moment';
import { ICardEventDetailProps } from './types';

const CardEventDetail: React.FC<ICardEventDetailProps> = ({
  place,
  startDate,
  endDate,
}) => {
  const renderStartEndDate = (): JSX.Element | null => {
    let resultText = '';
    const xStartDate = moment(startDate);
    const xEndDate = moment(endDate);
    const hourFormat = 'HH:mm';

    if (xStartDate.format('YYYY MM DD') === xEndDate.format('YYYY MM DD')) {
      if (xStartDate.format(hourFormat) === xEndDate.format(hourFormat)) {
        resultText = `${xStartDate.format('DD MMM YYYY')} - ${xStartDate.format(
          hourFormat
        )}`;
      } else {
        resultText = `${xStartDate.format('DD MMM YYYY')} - ${xStartDate.format(
          hourFormat
        )}/${xEndDate.format(hourFormat)}`;
      }
    } else {
      resultText = `${xStartDate.format('DD MMM')}/${xEndDate.format(
        'DD MMM'
      )} ${xStartDate.get('year')} - ${xStartDate.format(
        hourFormat
      )}/${xEndDate.format(hourFormat)}`;
    }

    if (resultText) {
      return <span style={{ fontSize: '13px' }}>{resultText}</span>;
    } else return null;
  };

  return (
    <>
      {place && (
        <>
          <Icon name="icon-pin-v2" style={{ marginRight: '15px' }} />
          <span className="text">{place}</span>
        </>
      )}
      {(startDate || endDate) && (
        <div className="detail-row date">
          <Icon name="icon-clock" style={{ marginRight: '10px' }} />
          {renderStartEndDate()}
        </div>
      )}
    </>
  );
};

export default CardEventDetail;
