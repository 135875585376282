import Icon from '@/components/base/icon';
import CustomButton from '../custom-button';
import { ICardDayDetailProps } from './types';
import { useApp } from '@wap-client/core';

const CardDayDetail: React.FC<ICardDayDetailProps> = ({ day }) => {
  const app = useApp();

  const convertDayString = () => {
    if (day) {
      const [dayNumber, word] = day.split(' ');
      if (dayNumber && word) {
        if (Number(dayNumber) > 1 && !word.endsWith('s')) {
          return `${day}s`;
        } else if (Number(dayNumber) <= 1 && word.endsWith('s')) {
          return `${dayNumber} ${word.slice(0, -1)}`;
        }
        return day;
      } else if (dayNumber && !word) {
        if (Number(dayNumber) <= 1) {
          return `${dayNumber} ${app.settings.translations['day']}`;
        } else {
          return `${dayNumber} ${app.settings.translations['day']}s`;
        }
      }

      return day;
    }
  };

  return (
    <CustomButton
      blur={false}
      background="#FFF"
      customColor="#000"
      opacity={0.5}
      round={20}
      iconBefore={<Icon size={'small'} fill="#1980DD" name="icon-km" />}
      fontSize={14}
      className="card-thumbnail-day-button"
    >
      {convertDayString()}
    </CustomButton>
  );
};

export default CardDayDetail;
